import React from 'react'
import { graphql } from 'gatsby'

import MasonryGrid from '_core/components/organisms/masonry/MasonryGrid'
import MandateCard from '_core/components/molecules/cards/mandate/MandateCard'

import useDynamicData from '_core/helpers/useDynamicData'

import LandingPage, {
  LandingPageContextProps,
} from 'public/templates/layouts/LandingPage'

interface Props {
  data: Queries.MandateSearchQuery
  pageContext: LandingPageContextProps
}

const MandateLanding = ({ data, pageContext }: Props) => {
  const landing = data.datoCmsLandingPage

  const {
    isDynamic,
    filterBarProps,
    contentProps,
    paginationProps,
    minExperience,
    maxExperience,
  } = useDynamicData({
    ...pageContext,
    dynamicType: 'mandate',
    dynamicContentQuery: dynamicContentQuery,
    dataResultSelector: 'mandates',
    withTerms: true,
    withExperience: true,
    withFilterBar: true,
  })

  let heroHeading: string | undefined = undefined
  let heroHeadingSuffix = ' years experience'
  if (minExperience) {
    heroHeading = minExperience.toString()
  }
  if (maxExperience) {
    heroHeading = `${heroHeading} - ${maxExperience}`
  }

  return (
    <LandingPage
      metadata={{
        seoTags: landing.seoMetaTags,
        currentPage: pageContext.currentPage,
      }}
      heroProps={{
        mainHeading: heroHeading
          ? heroHeading + heroHeadingSuffix
          : landing.heroMainHeading,
        smallHeading: landing.heroSmallHeading,
        imagePortrait: landing.imagePortrait.gatsbyImageData,
        imageLandscape: landing.imageLandscape.gatsbyImageData,
      }}
      pageContext={pageContext}
      filterBarProps={{ ...pageContext, ...filterBarProps }}
      paginationProps={{ ...pageContext, ...paginationProps }}
      summaryItemName="mandates"
      pageNum={pageContext.currentPage}
    >
      {isDynamic == true &&
        contentProps &&
        contentProps.items &&
        contentProps.items.length > 0 && (
          <div className="dynamic-content">
            <MasonryGrid className="mandate-search-grid">
              {contentProps.items.map((item: any) => {
                const mandate: Queries.DatoCmsPageMandate = item.node
                return (
                  <MandateCard
                    key={`m-${mandate.id}`}
                    mandate={mandate}
                    isDynamic
                  />
                )
              })}
            </MasonryGrid>
          </div>
        )}
    </LandingPage>
  )
}

export const pageQuery = graphql`
  query MandateSearch {
    datoCmsLandingPage(originalId: { eq: "1282080" }) {
      ...LandingPageTitleHero
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`

const dynamicContentQuery = `
query($filter: MandateFilter, $pagination: PaginationOptions!) {
  mandates(filter: $filter, pagination: $pagination) {
    totalCount
    edges {
      node {
        _modelApiKey
        active
        id
        slug
        title
        minExperience
        maxExperience
        createdAt
        departments {
          name
          slug
          id
          _modelApiKey
          departmentColor {
            red
            green
            blue
          }           
        }
        regions {
          name
          slug
          id
          _modelApiKey
        }
        levels {
          name
          slug
          id
          _modelApiKey
        }
        locations {
          name
          slug
          id
          _modelApiKey
        }
        products {
          name
          slug
          id
          _modelApiKey
        }
        sectors {
          name
          slug
          id
          _modelApiKey
        }
        secondaryDepartments {
          name
          slug
          id
          _modelApiKey
        }
        secondaryRegions {
          name
          slug
          id
          _modelApiKey
        }
        responsibilities {
          name
          slug
          id
          _modelApiKey
        }
        languages {
          ... on LanguageRequirementRecord {
            id
            language {
              name
              slug
            }
            proficiency {
              name
            }
          }
        }
        bulletPoints {
          ... on BulletPointRecord {
            bulletPoint
          }
        }
      }
    }
  }
}
`

export default MandateLanding
